import Api from '@/api/Api';
//import axios from 'axios';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/holidays';
const RESOURCE_EMPLOYEE_HOLIDAYS_BEGIN = '/api/employees';
// const RESOURCE_EMPLOYEE_HOLIDAYS_END = '/holidays';
const RESOURCE_EMPLOYEE_ANNUAL_HOLIDAYS = '/annual_holidays';
const RESOURCE_HOLIDAY_INCOMPATIBILITIES = '/holiday_incompatibilities';
const RESOURCE_INCOMPATIBILITIES = '/incompatibilities';
const RESOURCE_VACATION_PLANNING = '/api/vacation_planning';
const RESOURCE_EMPLOYEE_NEXT_HOLIDAYS = '/api/home/next_holidays?employeeId=';
const RESOURCE_EMPLOYEE_NEXT_PUBLIC_HOLIDAYS = '/api/home/next_public_holidays';
const RESOURCE_EMPLOYEE_NEXT_BIRTHDAYS = '/api/home/next_birthdays?limit=3'
const REPORT_ANNUAL_HOLIDAYS = '/api/reports/annual_holidays';
const REPORT_DAYS_PENDING = REPORT_ANNUAL_HOLIDAYS + '/pending_days';
const REPORT_DAYS_PENDING_DOWNLOAD = REPORT_DAYS_PENDING + '/download';

export default {

    // HOLIDAYS
    getHolidays(params) {
        return Api().get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getHoliday(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createHoliday(item) {
        return Api().post(RESOURCE_NAME, item)
            .catch((err) => errorHandler(err, err.response.data.detail)
            );
    },
    updateHoliday(item, id) {
        console.log('item', item);
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteHoliday(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    // getEmployeeHolidays(employeeID, params) {
    //     return Api().get(RESOURCE_EMPLOYEE_HOLIDAYS_BEGIN + `/${employeeID}` + RESOURCE_EMPLOYEE_HOLIDAYS_END, {params: params})
    //         .catch((err) => errorHandler(err, null));
    // },
    getEmployeeHolidays(employeeID, params) {
        return Api().get(RESOURCE_EMPLOYEE_HOLIDAYS_BEGIN + `/${employeeID}` + RESOURCE_EMPLOYEE_ANNUAL_HOLIDAYS, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getEmployeeHolidayIncompatibilities(employeeID, params) {
        return Api().get(RESOURCE_EMPLOYEE_HOLIDAYS_BEGIN + `/${employeeID}` + RESOURCE_HOLIDAY_INCOMPATIBILITIES, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getEmployeeIncompatibilities(employeeID, params) {
        return Api().get(RESOURCE_EMPLOYEE_HOLIDAYS_BEGIN + `/${employeeID}` + RESOURCE_INCOMPATIBILITIES, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getVacationPlanning(params) {
      return Api().get(RESOURCE_VACATION_PLANNING, {params: params})
          .catch((err) => errorHandler(err, null));
    },
    getNextHoliday(employeeID){
        return Api().get(RESOURCE_EMPLOYEE_NEXT_HOLIDAYS + `${employeeID}`)
        .catch((err) => errorHandler(err, null));
    },
    getNextPublicHolidays(){
        return Api().get(RESOURCE_EMPLOYEE_NEXT_PUBLIC_HOLIDAYS)
        .catch((err) => errorHandler(err, null));
    },
    getNextBirthDays(){
        return Api().get(RESOURCE_EMPLOYEE_NEXT_BIRTHDAYS)
        .catch((err) => errorHandler(err, null));
    },
    getReportDaysPending(params) {
        return Api().get(REPORT_DAYS_PENDING, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getReportDaysPendingFile(params) {
        return Api().get(REPORT_DAYS_PENDING_DOWNLOAD, {params: params, responseType: 'blob'})
            .catch((err) => errorHandler(err, null));
    },
    getYearsExercise() {
        return Api().get(REPORT_ANNUAL_HOLIDAYS + '/years_exercise')
            .catch((err) => errorHandler(err, null));
    }

}
