<script setup>
import {computed, defineProps, ref} from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/es'

dayjs.extend(isBetween)
dayjs.locale('es')

const props = defineProps({
  vacationPlanning: {
    type: Array,
    required: true,
    default: () => [],
  },
  fromDate: {
    type: String,
    required: true,
  },
  toDate: {
    type: String,
    required: true,
  },
})

const employees = ref(props.vacationPlanning.map(employee => ({
  ...employee,
  color: generateRandomColor()
})))

function generateRandomColor() {
  const hue = Math.floor(Math.random() * 360)
  return `hsl(${hue}, 70%, 80%)`
}

const rangeOfDays = computed(() => {
  const start = dayjs(props.fromDate)
  const end = dayjs(props.toDate)
  const days = []
  let current = start
  while (current.isSameOrBefore(end, 'day')) {
    days.push(current)
    current = current.add(1, 'day')
  }
  return days
})

const groupedDaysByMonth = computed(() => {
  const groups = {}
  rangeOfDays.value.forEach((day) => {
    const key = day.format('YYYY-MM')
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(day)
  })

  return Object.keys(groups).sort().map((monthKey) => {
    const daysInMonth = groups[monthKey]
    const firstDay = daysInMonth[0]
    const monthName = firstDay.format('MMMM YYYY')
    return {
      monthKey,
      monthName: capitalizeFirstLetter(monthName),
      days: daysInMonth
    }
  })
})

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const isWeekend = (day) => {
  const dayOfWeek = day.day()
  return dayOfWeek === 0 || dayOfWeek === 6
}

const getHolidayBars = (employee) => {
  return employee.holidays?.map(holiday => {
    const start = dayjs(holiday.fromDate)
    const end = dayjs(holiday.toDate)

    // Comparamos las fechas usando el formato YYYY-MM-DD para asegurar coincidencia exacta
    const startIdx = rangeOfDays.value.findIndex(
        day => day.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')
    )
    const endIdx = rangeOfDays.value.findIndex(
        day => day.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')
    )

    if (startIdx === -1 || endIdx === -1) return null

    return {
      startDay: startIdx,
      width: endIdx - startIdx + 1,
      color: employee.color,
      text: holiday.observations || 'Vacaciones',
      details: holiday
    }
  }).filter(bar => bar !== null) || []
}

const showHolidayDetails = (holiday) => {
  alert(`Vacaciones: ${holiday.details.observations}\nDesde: ${holiday.details.fromDate}\nHasta: ${holiday.details.toDate}`)
}
</script>

<template>
  <div class="holiday-grid-container mt-5">
    <div class="table-wrapper">
      <div class="gantt-container">
        <table>
          <thead>
          <tr>
            <th
                class="employee-column"
                rowspan="2">
              Empleado
            </th>
            <th
                v-for="monthGroup in groupedDaysByMonth"
                :key="monthGroup.monthKey"
                :colspan="monthGroup.days.length"
                class="month-header"
            >
              {{ monthGroup.monthName }}
            </th>
          </tr>
          <tr>
            <th
                v-for="day in rangeOfDays"
                :key="day.format('YYYY-MM-DD')"
                class="day-column"
            >
              <div class="day-container">
                <span class="day-number">{{ day.format('DD') }}</span>
                <span class="day-name">{{ day.format('ddd') }}</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="employee in employees"
              :key="employee.id"
              class="employee-row">
            <td class="employee-name">
              <div class="employee-name-content">
                {{ employee.name }}
              </div>
            </td>
            <td
                v-for="day in rangeOfDays"
                :key="day.format('YYYY-MM-DD')"
                :class="{ 'weekend-cell': isWeekend(day) }"
                class="day-cell"
            />
          </tr>
          </tbody>
        </table>

        <div class="holidays-overlay">
          <div
              v-for="(employee, index) in employees"
              :key="employee.id"
              class="employee-holidays-row"
              :style="{
              top: `calc(var(--header-height) + ${index} * var(--row-height))`
            }"
          >
            <div
                v-for="(bar, barIndex) in getHolidayBars(employee)"
                :key="barIndex"
                class="holiday-bar"
                :style="{
                left: `calc(var(--employee-column-width) + (${bar.startDay} * var(--cell-width)))`,
                width: `calc(${bar.width} * var(--cell-width))`,
                backgroundColor: bar.color
              }"
                @click="showHolidayDetails(bar)"
            >
              {{ bar.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style
    scoped
    lang="scss">

.holiday-grid-container {
  width: 100%;
  max-height: 600px;
  overflow: auto;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

.gantt-container {
  --cell-width: 50px;
  --row-height: 42px; /* Altura total de la fila */
  --header-height: 80px;
  --employee-column-width: 200px;
  --bar-height: 30px; /* Nueva variable para la altura de la barra */
  position: relative;
  width: max-content;
}

table {
  border-collapse: collapse;
  width: max-content;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #ccc;
  padding: 0;
  text-align: center;
  width: var(--cell-width);
  height: var(--row-height);
  box-sizing: border-box;
  overflow: hidden;
}

.employee-column {
  background-color: #f4f4f4;
  position: sticky;
  left: 0;
  z-index: 10; // Aumentado para estar siempre por encima de las barras
  width: var(--employee-column-width);
}

.employee-name {
  background-color: #fff;
  font-weight: bold;
  position: sticky;
  left: 0;
  z-index: 10; // Aumentado para estar siempre por encima de las barras
  width: var(--employee-column-width);
  padding: 0;
}

.employee-name-content {
  padding: 6px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff; // Asegura que el fondo sea sólido
}

.month-header {
  background-color: #eaeaea;
  font-weight: bold;
  height: calc(var(--row-height) * 0.8);
}

.day-column {
  background-color: #f7f7f7;
  height: calc(var(--row-height) * 1.2);
}

.day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}

.day-number {
  font-weight: bold;
}

.day-name {
  font-size: 0.8em;
  color: gray;
}

.weekend-cell {
  background-color: #f0f0f0;
}

.employee-row {
  height: var(--row-height);
}

.day-cell {
  height: var(--row-height);
}

.holidays-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
}

.employee-holidays-row {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--row-height);
}

.holiday-bar {
  position: absolute;
  height: var(--bar-height);
  /* Calculamos la posición top para centrar perfectamente */
  top: calc((var(--row-height) - var(--bar-height)) / 2);
  border-radius: 20px;
  /* Reducimos el padding horizontal para que no afecte al ancho total */
  padding: 0 4px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Aseguramos que el padding y el borde no afecten al tamaño total */
  box-sizing: border-box;
  margin: 0;

  &:hover {
    opacity: 0.9;
  }
}
</style>
