<template>
  <div>
    <el-card
      shadow="hover"
      :style="headerStyle"
    >
      <template #header>
        <div class="user-info-header">
          <el-avatar
            class="user-info-avatar"
            :src="aPhoto"
          >
            {{ userInitials }}
          </el-avatar>
          <span style="margin-left: 10px; font-size: 14px;">
            {{ userFullName }}

          </span>
          <span style="margin-left: auto">
            <el-button
              size="small"

              @click="dialogVisible = true"
            >
             <font-awesome-icon icon="thumbs-up"/> {{ totalSignins }} /
             <font-awesome-icon icon="clock" />{{ totalWorklog }}
            </el-button>
            <OccupationProgress :percentaje="occupationPercent" />
          </span>
        </div>
      </template>
      <div v-if="!isOnVacations && !isOnPublicHoliday">
        <i class="el-icon-s-cooperation" /> {{ client }} - <i class="el-icon-s-claim" /> {{ project }}<br>
        <small> {{ timeentry }} </small>
      </div>
      <div v-else-if="isOnVacations">
        <el-icon color="#60626600">
          <ColdDrink />
        </el-icon> De vacaciones desde {{ formatDateAndMonth(info.holidays.fromDate) }} hasta {{ formatDateAndMonth(info.holidays.toDate) }}<br>
<!--        </el-icon> De vacaciones desde {{ formatDateAndMonth(holidaysFrom) }} hasta {{ formatDateAndMonth(holidaysTo) }}<br>-->
      </div>
      <div v-else-if="isOnPublicHoliday">
        <span v-if="info.publicHoliday.type === 'nacional'">
          <el-icon color="#60626600">
          <ColdDrink />
        </el-icon>  {{ formatDateAndMonth(info.publicHoliday.date) }} {{ info.publicHoliday.description }}, Fiesta Nacional<br>
<!--        </el-icon>  {{ formatDateAndMonth(publicHoliday.date) }} {{ publicHoliday.description }}, Fiesta Nacional<br>-->
        </span>
        <span v-else>
           <el-icon color="#60626600">
            <ColdDrink />
          </el-icon> {{ formatDateAndMonth(info.publicHoliday.date) }} {{ info.publicHoliday.description }} en {{ info.publicHoliday.workPlaceName }}<br>
<!--          </el-icon> {{ formatDateAndMonth(publicHoliday.date) }} {{ publicHoliday.description }} en {{ publicHoliday.workPlaceName }}<br>-->
        </span>
      </div>
      <div v-if="!isOnVacations && !isOnPublicHoliday">
        <el-divider>Presencia</el-divider>
        <presence :presence="info.presence" />
      </div>

      <div v-if="absences">
        <el-divider>Ausencia</el-divider>

        <Absence :absence="info.absence" />
      </div>
    </el-card>
    <el-dialog
      v-model="dialogVisible"
      title="Información de empleado"
      class="employee-info-dialog"
    >
      <user-info-extended :info="info" />
    </el-dialog>
  </div>
</template>

<script setup>
import _ from 'lodash'
import UserInfoExtended from './UserInfoExtended'
import OccupationProgress from './OccupationProgress'
import Presence from './Presence'
import Absence from './Absence'
import {toHours} from '../mixin/common'
import moment from "moment";
import {computed, ref, defineProps, onMounted} from "vue";
import {ColdDrink} from "@element-plus/icons-vue";

const props = defineProps({
  info: {
    type: Object,
    required: true
  }
})

const dialogVisible = ref(false);
const baseUri = process.env.VUE_APP_BASE_URI;
// const holidaysFrom = ref('');
// const holidaysTo = ref('');
// const publicHoliday = ref({});

onMounted(() => {
  // if (props.info.holidays && props.info.holidays.fromDate && props.info.holidays.toDate) {
  //   holidaysFrom.value = props.info.holidays.fromDate;
  //   holidaysTo.value = props.info.holidays.toDate;
  // }
  // if (props.info.publicHoliday && props.info.publicHoliday.date) {
  //   publicHoliday.value = props.info.publicHoliday;
  // }
})

const headerStyle = computed(() => {
  if(!isWorking()) {
    if (isResting() || isRestingPublicHoliday()) {
      return {
        'border-top': '#808080' + ' solid 4px',
        'background': '#f5f4f4',
      };
    } else if (isAbsence()){
      return  {
        'border-top': 'cyan' + ' solid 4px',
        'background': 'lightcyan',
      };
    } else {
      return {};
    }
  }
  return {'border-top': props.info.worklog[0]?.color + ' solid 4px'};
})
const project = computed(() => {
  return props.info.worklog?.[0]?.projectName ?? 'Sin proyecto';
})
const client = computed(() => {
  return props.info.worklog?.[0]?.clientName ?? 'Sin cliente';
})
const timeentry = computed(() => {
  return props.info.worklog?.[0]?.description;
})
const aPhoto = computed(() => {
  if(!props.info?.employee?.photo) {
    return null;
  }
  return baseUri.replace(/\/$/, "") + '/images/employees/' + props.info.employee.photo;
})
const userFullName = computed(() => {
  return `${props.info?.employee?.name} ${props.info.employee.lastname}`;
})
const userInitials = computed(() => {
  return props.info?.employee?.name.charAt(0).toUpperCase() + _.map(props.info.employee.lastname.split(' '), el => el.charAt(0).toUpperCase()).join('')
})
const totalSignins = computed(() => {
  return toHours(props.info?.presence?.totalWorked ?? 0);
})
const totalWorklog = computed(() => {
  return toHours(props.info?.totalWorked);
})
const  occupationPercent = computed(() => {
  const val = parseFloat(((props.info.totalWorked * 100) / (props.info.presence?.totalWorked ?? 0)).toFixed(2));
  return isNaN(val) ? 0 : ((val > 100) ? 100 : val);
})
const isOnVacations = computed(() => {
  return isResting();
})
const isOnPublicHoliday = computed(() => {
  return isRestingPublicHoliday();
})
const absences = computed(() => {
  return isAbsence();
})


const isWorking = ()  => {
  return props.info.worklog?.[0] && (props.info.worklog?.[0]?.start !== null) && (props.info.worklog?.[0]?.end === null)
}
const isResting = ()  => {
  // holidaysFrom.value = props.info.holidays.fromDate;
  // holidaysTo.value = props.info.holidays.toDate;
  return props.info?.holidays?.length !== 0;
}
const isRestingPublicHoliday = () => {
  // publicHoliday.value = props.info.publicHoliday;
  return props.info?.publicHoliday?.length !== 0;
}
const isAbsence = ()  => {
  return props.info?.absence?.length !== 0;
}
// eslint-disable-next-line no-unused-vars
const size = (object) => {
  return _.size(object);
}


const  formatDateAndMonth = (value) => {
  if (value) {
    return moment(value, "DD-MM-YYYY").format('DD-MM');
  } else {
    return '';
  }
}


</script>

<style lang="scss">

.user-info-header {
  display: flex;
  align-items: center;
}

.user-info-avatar {
  min-width: 40px;
  min-height: 40px;
}

.el-table.condensed-table td, .el-table.condensed-table th{
  padding: 3px;
}

.employee-info-dialog {
  &.el-dialog {
    display: flex;
    flex-direction: column;
    margin-top: 15vh !important;
  }

  .el-dialog__body {
    background: #f3f0fb !important;
    padding: 20px !important;
    overflow: visible !important;
    min-height: unset !important; /* Esto eliminará cualquier min-height previo */
  }
}

</style>
