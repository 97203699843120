<script setup>
import {defineProps, defineEmits, ref, onMounted} from 'vue';
import moment from "moment/moment";
import HolidayService from "@/api/services/holiday.service";
import {useStore} from "vuex";
import {ElNotification} from "element-plus";
import EmployeesWithPendingVacationDays from "@/views/holidays/EmployeesWithPendingVacationDays.vue";

const store = useStore();

defineProps({
  departments: {
    type: Array,
    default: () => []
  },
  yearsExercise: {
    type: Array,
    default: () => []
  }
});

// Definición de emisiones
const emit = defineEmits(['close-dialog'])

onMounted(() => {
  searchDaysPending();
});

const daysPendingFormModel = ref({
  department: 0,
  year: moment().year(),
  exportFormat: 'csv'
});

const daysPendingForm = ref(null);
const employeesWithPendingDays = ref(null);
const optionsFileFormat = ref([{id: 'csv', name: 'CSV'}, {id: 'excel', name: 'Excel'}])

const searchDaysPending = async () => {
  const params = {
    year: daysPendingFormModel.value.year,
  }

  if (daysPendingFormModel.value.department && daysPendingFormModel.value.department !== 0) {
    params.department = daysPendingFormModel.value.department;
  }

  try {
    await store.dispatch('setLoading', true);
    const res = await HolidayService.getReportDaysPending(params);
      employeesWithPendingDays.value = res.data;
  } catch (error) {
    console.error('Error fetching pending days:', error);
  } finally {
    await store.dispatch('setLoading', false);
  }
}

const getFileReportsDaysPending = async () => {
  try {
    const params = {
      year: daysPendingFormModel.value.year,
    };

    if (daysPendingFormModel.value.department !== 0) {
      params.department = daysPendingFormModel.value.department;
    }

    if (daysPendingFormModel.value.exportFormat) {
      params.format = daysPendingFormModel.value.exportFormat;
    }

    await store.dispatch('setLoading', true);
    const fileExtension = daysPendingFormModel.value.exportFormat === 'excel' ? 'xlsx' : 'csv';
    const res = await HolidayService.getReportDaysPendingFile(params, fileExtension);

    handleFileDownload(res, fileExtension);

    ElNotification({
      type: 'success',
      message: 'Informe de días pendientes descargado correctamente',
      duration: 6000
    });
  } catch (error) {
    console.error('Error downloading report:', error);
    ElNotification({
      type: 'error',
      message: 'Error al descargar el informe de días pendientes',
      duration: 6000
    });
  } finally {
    await store.dispatch('setLoading', false);
  }
};

const handleFileDownload = (res, fileExtension) => {
  const fileName = getFileName(res, fileExtension);
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const getFileName = (res, fileExtension) => {
  const contentDisposition = res.headers['content-disposition'];
  let fileName = `informe_vacaciones_dias_pendientes_.${daysPendingFormModel.value.year}.${fileExtension}`;

  if (contentDisposition) {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
};

// Función para cerrar el diálogo
const closeDialog = () => {
  emit('close-dialog')
}

</script>

<template>
  <img
      class="close-icon pointer"
      src="@/assets/images/aspa_blue_small_entrada.png"
      alt=""
      @click="closeDialog"
  >
  <div class="my-header">
    <svg
        id="asterisk"
        xmlns="http://www.w3.org/2000/svg"
        width="724"
        height="1024"
        viewBox="0 0 724 1024"
    >
      <path
          d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
      />
    </svg>
    <span class="text-header ml-5 mt-3">Días Pendientes</span>

    <img
        class="close-icon pointer"
        src="@/assets/images/aspa_blue_small_entrada.png"
        alt=""
        @click="closeDialog"
    >
  </div>
  <div class="mt-2 my-body">
    <el-form
        ref="daysPendingForm"
        class="days-pending-form"
        :model="daysPendingFormModel"
        name="daysPendingForm"
        status-icon
        @submit.prevent
    >
      <div class="form-item">
        <div class="row">
          <div class="col-md-6">
            <!--              Select year exercise -->
            <label class="w-100">Ejercicio</label>
            <el-select
                v-model="daysPendingFormModel.year"
                @change="searchDaysPending"
            >
              <el-option
                  v-for="item in yearsExercise"
                  :key="item.id"
                  :label="item.yearExercise"
                  :value="item.yearExercise"
              />
            </el-select>
          </div>
          <div class="col-md-6">
            <!--              Select department -->
            <label class="w-100">Departamento</label>
            <el-select
                v-model="daysPendingFormModel.department"
                placeholder="Todos"
                clearable
                @change="searchDaysPending"
            >
              <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="row">
          <div class="col-md-6">
            <label class="w-100">
              Formato de exportación
            </label>
            <el-select
                v-model="daysPendingFormModel.exportFormat"
                placeholder="Formato"
            >
              <el-option
                  v-for="item in optionsFileFormat"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="col-md-6">
            <button
                style="width: 429px; margin-top: 21px"
                class="btn btn_yellow btn-search"
                @click="getFileReportsDaysPending"
            >Descargar
            </button>
          </div>
        </div>
        <div v-if="employeesWithPendingDays">
          <!--            <holidays-employees-with-pending-days :holidays-employees-with-pending-days="holidaysEmployeesWithPendingDays"/>-->
          <employees-with-pending-vacation-days :employees-with-pending-days="employeesWithPendingDays"/>

        </div>
      </div>
    </el-form>

  </div>
</template>

<style
    scoped
    lang="scss">

.loader {
  z-index: auto;
}

</style>
