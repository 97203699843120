<template>
  <form-project
      v-if="showFormProject"
      :project="project"
      @change="updateObservation"
  />
</template>
<script>
import FormProject from "./FormProject.vue";
import ProjectsService from '@/api/services/projects.service';
import {ElNotification} from "element-plus";
//import moment from 'moment';
export default {
  components: {FormProject},
  data() {
    return {
      showFormProject: false,
      project: {
        name: '',
        date_ini: '',
        client: {
          id: '',
          name: ''
        },
        nro_horas: '',
        projectType: {
          id: '',
          name: ''
        },
        projectManager: '',
        clockifyProject: '',
        consumedTime: '',
        contractedTime: '',
        tag: '',
        initialDate: '',
        lastSynchronization: '',
        observations: '',
        minConsumptionHours: 0,
        reviewPeriodMonths: null,
      },


    }
  },
  mounted() {
    this.getProject(this.$route.params.id);
  },
  methods: {
    getProject(id) {
      const $this = this;
      ProjectsService.getProject(id).then((result) => {

        $this.project = result.data;
        this.showFormProject = true

        //moment.locale('es');
        //$this.project.lastSynchronization = moment($this.project.lastSynchronization,"yyyy-MM-DDTHH:mm:ssZ").format("yyyy-MM-dd HH:mm:ss");
      }).catch(err => {
        console.error("Types", err);
        ElNotification({
          title: 'Proyecto no encontrado',
          message: 'Error al intentar obtener los campos del proyecto seleccionado' + err,
          type: 'error',
          duration: 6000
        });
        this.$store.state.loading = false;
        this.showFormProject = false
      });
    },
    updateObservation(obj) {
      console.log("Update Project", obj);
      ProjectsService.udpdateObservations({
            observations: obj.observations,
            tag: obj.tag,
            minConsumptionHours: obj.minConsumptionHours,
            reviewPeriodMonths: obj.reviewPeriodMonths
          },
          this.$route.params.id)
          .then(() => {
            ElNotification({
              title: 'Actualización del proyecto',
              message: 'Se han actualizado los del proyecto seleccionado',
              type: 'success',
              duration: 6000
            });
          }).catch(err => {
        ElNotification({
          title: 'Error al intentar modificar el proyecto seleccionado',
          message: err,
          type: 'error',
          duration: 6000
        });
        this.$store.state.loading = false;
      });
    }
  }
}
</script>
