<template>
  <div v-if="projectModel">
    <div class="d-flex flex-row justify-content-start my-2 label-common">
      <div class="title-text w-75 col-10">
        Proyecto:
        <span class="name-project"> {{ projectModel.name }} </span>
      </div>

      <div class="form-item col date-picker align-content-center justify-content-center ">
        <label>
          Fecha de inicio
        </label>
        <el-date-picker
            v-model="projectModel.initialDate"
            type="date"
            placeholder="Fecha Inicio"
            style="width: 250px;"
            class="col"
            format="DD/MM/YYYY"
            :prefix-icon="'el-icon-date'"
            :picker-options="{ firstDayOfWeek: 1 }"
            :readonly="true"
        />
      </div>
    </div>
    <el-row>
      <el-card
          class="card-search w-100"
          body-style="padding:0"
      >
<!--        <el-card-body>-->
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Nombre</label>
                <el-input
                    v-model="projectModel.name"
                    class="search-input"
                    :color="'light'"
                    :placeholder="'Ingrese nombre del proyecto'"
                    :readonly="true"
                />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Horas contratadas</label>
                <el-input
                    v-model="contractedTime"
                    class="search-input"
                    color="light"
                    :placeholder="'0.00'"
                    :readonly="true"
                />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <label class="w-100">Cliente</label>
                <el-select
                    v-model="projectModel.client"
                    class="search-input"
                    color="light"
                    placeholder="Todos"
                    :value-key="'@id'"
                    disabled
                >
                  <el-option
                      v-for="item in optionsClient"
                      :key="item.id"
                      :label="item.name || 'Nameless'"
                      :value="item"
                  />
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Tipo</label>
                <el-select
                    v-model="projectModel.projectType"
                    class="w-100"
                    color="light"
                    placeholder="TODOS"
                    disabled
                >
                  <el-option
                      v-for="item in optionsType"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                  />
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Etiqueta</label>
                <el-input
                    v-model="projectModel.tag"
                    class="search-input"
                    :color="'light'"
                    :placeholder="'Ingrese la etiqueta'"
                />
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="form-item">
                <label
                    for=""
                    class="w-100">Periodo de revisión (Consumo mínimo)</label>
                <el-select
                    v-model="projectModel.reviewPeriodMonths"
                    class="w-100"
                    color="light"
                    placeholder="Perido de revisión">
                  <el-option
                      v-for="item in optionsReviewPeriod"
                      :key="item.id"
                      :label="item.name"
                      :value="item.value"
                  />
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <label
                    for=""
                    class="w-100">Consumo mínimo de horas</label>
                <el-input
                    v-model="projectModel.minConsumptionHours"
                    class="search-input"
                    type="number"
                    :color="'light'"
                    :placeholder="'Número de horas'"/>
              </div>
            </el-col>
          </el-row>
<!--        </el-card-body>-->
      </el-card>
    </el-row>

    <!-- Rompiendo de aqui para abajo -->

    <el-row>
      <el-col
          :span="24"
          class="label-common"
      >
        <label>Observaciones</label>
        <textarea
            v-model="projectModel.observations"
            class="w-100 p-3"
            rows="5"
            @change="onChangeObs"
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="14">
        <div class="d-flex flex-column">
          <div
              class="
              d-flex
              row
              align-items-center
              label-common
              mt-2
            "
          >
            <label class="w-75">&Uacute;ltima sincronizaci&oacute;n</label>
            <el-date-picker
                v-model="projectModel.lastSynchronization"
                :name="'from-date'"
                type="datetime"
                class="w-50"
                placeholder="Fecha Inicio"
                format="DD-MM-YYYY HH:mm:ss"
                :prefix-icon="'el-icon-date'"
                :picker-options="{ firstDayOfWeek: 1 }"
                :readonly="true"
            />
          </div>
          <div
              class="d-flex row align-items-center label-common mt-4"
          >
            <label class="w-75">Horas consumidas</label>
            <el-input
                v-model="consumedTime"
                class="w-50 pr-3"
                :color="'light'"
                :placeholder="'0.00%'"
                :readonly="true"
            />
          </div>
        </div>
        <!--TODO: Añadir los empleados -->
        <div v-if="false"
            class="d-flex row align-items-center mt-2 w-50"
        >
          <button
              class="btn btn-block btn_red m-2 btn-add"
              @click="showModalAddEmployee=true"
          >
            Añadir empleado al proyecto
          </button>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="d-flex justify-content-end label-common">
          <label class="align-self-end mb-4 pb-3">% Horas consumidas</label>
          <div class="text-porciento ml-4">
            {{ consumedTimePercent }}
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="d-flex justify-content-end mt-5">
      <button
          class="btn btn-block btn_red m-2 btn-action"
          @click="() => $router.push({ name: 'projects' })"
      >
        Volver
      </button>
      <button
          type="primary"
          class="btn btn-block btn_yellow m-2 btn-action"
          @click="onSubmit"
      >
        Guardar
      </button>


      <!-- Modal Añadir empleados a proyecto -->

      <el-dialog
          v-model="showModalAddEmployee"
          :show-close="false"
          size="medium"
          @closed="closeModalAddEmployee()"
          width="890"
          :center="true"
          :lock-scroll="true"
          :style="{ borderRadius: `var(--el-border-radius-round)` }"
      >
        <div class="my-header">
          <svg
              id="asterisk"
              xmlns="http://www.w3.org/2000/svg"
              width="724"
              height="1024"
              viewBox="0 0 724 1024"
          >
            <path
                d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
            />
          </svg>
          <span class="text-header ml-5 mt-3">Añadir Empleados</span>
          <img
              class="close-icon pointer"
              src="@/assets/images/aspa_blue_small_entrada.png"
              alt=""
              @click="closeModalAddEmployee()"
          >
        </div>
        <div class="my-body">
          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <label class="modal-add-label">Añadir empleados</label>
              <font-awesome-icon
                  class="btn_icon modal-add-employee-icon"
                  icon="plus-circle"
                  title="Añadir"
                  @click="addEmployeeFromConfigModal()"
              />
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="table">
                <div
                    v-if="selectedProjectList.employees && selectedProjectList.employees.length > 0"
                >
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in selectedProjectList.employees"
                        :key="employee.id"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        <font-awesome-icon
                            class="btn_icon modal-remove-employee-icon"
                            icon="minus-circle"
                            title="Eliminar"
                            @click="addEmployeeToProject(employee)"
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <label class="mt-1">No hay empleados todavía...</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal offset-md-3">
            <div class="col-sm-4">
              <button
                  class="btn btn-block btn_red"
                  type="button"
                  @click="closeModalAddEmployee()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-4">
              <button
                  class="btn btn_blue"
                  type="button"
                  @click="requestSaveEmployeeToProject(selectedProjectList)"
              >
                Aceptar
              </button>
            </div>
          </div>

        </div>

      </el-dialog>


      <!-- Modal Añadir Nuevos empleados al proyecto-->

      <el-dialog
          v-model="showModalEmployees"
          :title="'Añadir empleados'"
          size="medium"
          width="890"
          :center="true"
          :lock-scroll="true"
          :style="{ borderRadius: `var(--el-border-radius-round)` }"
      >
        <div>
          <div class="row modal-item">
            <div class="col-md-9 form-item">
              <el-input
                  v-model="searchNameModalEmployees"
                  label="Nombre"
                  placeholder="Nombre"
                  @change="filterEmployeesByName($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="table">
                <div v-if="listAllEmployees && listAllEmployees.length > 0">
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Departamento</th>
                      <th>Puesto</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in listAllEmployees"
                        :key="employee.id"
                        class="pointer"
                        @click="addEmployeeToProject(employee)"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        {{ employee.job ? employee.job.name : null }}
                      </td>
                      <td>
                        {{
                          employee.job && employee.job.department ? employee.job.department.name
                              : '--'
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <SharedPagination
                      v-if="listAllEmployees && allEmployeesListTotal > itemsPerPage"
                      class="pagination"
                      :page="page"
                      :total-results="allEmployeesListTotal"
                      :page-size="itemsPerPage"
                      style="padding: 0px 0px 20px 0px;"
                      @change="page = $event; getEmployees();"
                  />
                </div>
                <div v-else>
                  <label>No hay empleados disponibles</label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </el-dialog>


    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import SharedInput from "@/components/shared/SharedInput.vue";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import ProjectsService from "@/api/services/projects.service";
import Notification from "@/utils/plugins/NotificationService";
import {computed, onMounted, ref, defineProps, defineEmits} from "vue";
import {useStore} from "vuex";

import EmployeeService from '@/api/services/employee.service';
import {ElNotification} from "element-plus";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});
const store = useStore();
const emits = defineEmits(['change']);
const isDisable = ref(false);
const optionsClient = ref([]);
const optionsType = ref([]);

// Modal
const showModalAddEmployee = ref(false);
const showModalEmployees = ref(false);
const selectedProjectList = ref({
  employees: [],
});

// Modal Add
const listAllEmployees = ref([]);
const allEmployeesListTotal = ref(0);
const page = ref(1);
const itemsPerPage = ref(10);
const params = ref({});
const searchNameModalEmployees = ref('');

const projectModel = ref(null)
const optionsReviewPeriod = [
  {id: 0, name: "no revisar", value: 0},
  {id: 1, name: "cada mes", value: 1},
  {id: 2, name: "cada 2 meses", value: 2},
  {id: 3, name: "cada 3 meses", value: 3},
  {id: 4, name: "cada 4 meses", value: 4},
  {id: 5, name: "cada 5 meses", value: 5},
  {id: 6, name: "cada 6 meses", value: 6},
];

onMounted(() => {
  getTypes();
  getClients();
  projectModel.value = props.project;

})
// eslint-disable-next-line no-unused-vars
const nrohoras = computed(() => {
  if (!projectModel.value.contractedTime) return 0.0 + " h";
  const hours = (projectModel.value.contractedTime / 3600).toFixed(2);
  return hours + " h";
})
const consumedTimePercent = computed(() => {
  if (!projectModel.value.consumedTime) return 0.0 + "%";
  if (!projectModel.value.contractedTime) return 0.0 + "%";
  console.log("Porciento");
  const hcosumend = projectModel.value.consumedTime / 3600;
  const hcontract = projectModel.value.contractedTime / 3600;
  const porc = (hcosumend * 100) / hcontract;
  return porc ? Math.floor(porc) + "%" : 0 + "%";
})
const consumedTime = computed(() => {
  if (!projectModel.value.consumedTime || projectModel.value.consumedTime === 0)
    return "0 h";

  const hours = (projectModel.value.consumedTime / 3600).toFixed(2);

  return hours + " h";
})
const contractedTime = computed(() => {
  if (!projectModel.value.contractedTime || projectModel.value.contractedTime === 0)
    return "0 h";

  const hours = (projectModel.value.contractedTime / 3600).toFixed(2);

  return hours + " h";
})


// eslint-disable-next-line no-unused-vars
const tableRowClassName = (param) => {
  if (param.rowIndex % 2 === 0) {
    console.log("warning-row", param.rowIndex);
    return "warning-row";
  } else console.log("success-row", param.rowIndex);
  return "success-row";
}
const getTypes = () => {
  ProjectsService.getTypes()
      .then((result) => {
        optionsType.value = result.data["hydra:member"];
      })
      .catch((err) => {
        console.error("Types", err);
        Notification.addNotification(
            "Error al intentar eliminar el empleado seleccionado.",
            "error",
            6000
        );
        store.state.loading = false;
      });
}
const getClients = () => {
  ProjectsService.getClients()
      .then((result) => {
        optionsClient.value = result.data["hydra:member"];
      })
      .catch((err) => {
        console.error("Types", err);
        Notification.addNotification(
            "Error al intentar eliminar el empleado seleccionado.",
            "error",
            6000
        );
        store.state.loading = false;
      });
}
const onChangeObs = () => {
  isDisable.value = !!(projectModel.value && projectModel.value.observations);
}
const onSubmit = () => {
  console.log("Submit", projectModel.value);
  projectModel.value.minConsumptionHours = parseInt(projectModel.value.minConsumptionHours);
  if (projectModel.value.minConsumptionHours > (projectModel.value.contractedTime / 3600)) {
    ElNotification({
      title: 'Error',
      message: 'El consumo mínimo no puede ser mayor a las horas contratadas',
      type: 'error',
      duration: 6000
    });
    projectModel.value.minConsumptionHours = 0;
  } else {
    emits("change", projectModel.value);
  }

}


// Funciones Modal
const closeModalAddEmployee = () => {
  showModalAddEmployee.value = false;
}
const addEmployeeFromConfigModal = () => {
  openModalEmployees();
}

const openModalEmployees = () => {
  getEmployees();
  showModalEmployees.value = true;
}

const getEmployees = () => {
  delete params.value.name
  params.value = {
    ...params.value,
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    active: true,
  }

  store.state.loading = true;
  EmployeeService.getEmployees(params.value)
      .then((res) => {
        store.state.loading = false;
        listAllEmployees.value = res.data['hydra:member'];
        allEmployeesListTotal.value = res.data['hydra:totalItems']
      })
      .catch(() => {
        store.state.loading = false;
        Notification.addNotification('Error al obtener los empleados', 'error', 6000);
      });
}

const filterEmployeesByName = (name) => {
  delete params.value.name;
  if (name && name.length > 0) {
    name = name.toLowerCase();

    params.value = {
      ...params.value,
      orSearch_fullName: name,
    }
  } else {
    delete params.value['orSearch_fullName']
  }

  page.value = 1;
  getEmployees();
  delete params.value.name;

}

const addEmployeeToProject = (employee) => {
  if (selectedProjectList.value != null) {
    if (selectedProjectList.value.employees) {
      let existMember = selectedProjectList.value.employees.findIndex(member => member.id === employee.id);
      if (existMember == -1) {
        selectedProjectList.value.employees.push(employee);
        ElNotification({
          type: 'success',
          message: 'Empleado añadido',
          duration: 6000
        })
      } else {
        selectedProjectList.value.employees.splice(existMember, 1);
        ElNotification({
          type: 'warning',
          message: 'Empleado eliminado',
          duration: 6000
        })
      }
    } else {
      selectedProjectList.value.employees.push(employee);
      ElNotification({
        type: 'success',
        message: 'Empleado añadido',
        duration: 6000
      })
    }
  }
}

const requestSaveEmployeeToProject = () => {
  console.log('Aceptar')
  closeModalAddEmployee();
}


</script>
<style
    lang="scss"
    scoped>

.name-project {
  font-size: 1em;
  //font-weight: 600;
  color: #f9156f;
}

.text-porciento {
  font-size: 100px;
  font-weight: 900;
  color: #f91570;
}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}

.card-search {
  border-radius: 15px;
  height: 300px;
  background: url("../../assets/icons/bombilla.png"), white;
  background-size: 24%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}

.right {
  float: right;
}

.el-input__inner {
  font-weight: bold;
  border-radius: 150px !important;
}

.btn-action {
  width: 200px;
  height: 40px;
}

.search-input {
  width: 100%;
}

.title-text {
  text-align: left;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}

.label {
  margin-top: auto;
}

.btn-add {
  height: 40px;
  font-weight: bold;
  width: 300px;
  min-width: 300px;
}

.my-header {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text-header {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;
  }

  .close-icon {
    width: 8%;
    height: 20%;
  }

  .inter-icon {
    width: 2%;
    height: 2%;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 2.2em;
    fill: #f9156f;
  }

}

.my-body {
  margin-right: 5%;
}

.modal-add-employee-icon {
  margin-left: 20px;
  color: green;
}

.modal-add-employee-icon:hover {
  cursor: pointer;
}

.modal-remove-employee-icon {
  color: red;
}

.modal-remove-employee-icon:hover {
  cursor: pointer;
}
</style>
